import React from 'react'
import { createUseStyles } from 'react-jss'

import Logo from '../../components/logo/Logo'

import Sun from './components/Sun'
import Sky from './components/Sky'
import Sand from './components/Sand'
import WaterBase from './components/WaterBase'
import WaterFoam from './components/WaterFoam'
import WaterShadowLeft from './components/WaterShadowLeft'
import WaterShadowRight from './components/WaterShadowRight'
import WaterShadowCenter from './components/WaterShadowCenter'
import CloudLargeLeft from './components/CloudLargeLeft'
import CloudLargeRight from './components/CloudLargeRight'

import { BP_MD } from '../../constants/breakpoints'

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    height: '100vh',
    overflow: 'hidden',
    zIndex: -1,
  },
  sceneWrapper: {
    position: 'absolute',
    top: 0,
    left: '-50%',
    width: '200%',
    height: '100%',
    zIndex: -1,
    [`@media (min-width: ${BP_MD})`]: {
      left: 0,
      width: '100%',
    },
  },
})

const Beach = () => {
  const classes = useStyles()

  return (
    <section className={classes.root}>
      <Logo />
      <div className={classes.sceneWrapper}>
        <Sky />
        <Sun />
        <CloudLargeLeft />
        <CloudLargeRight />
        <Sand />
        <WaterBase />
        <WaterFoam />
        <WaterShadowLeft />
        <WaterShadowRight />
        <WaterShadowCenter />
      </div>
    </section>
  )
}

export default Beach
