import React, { useEffect } from 'react'
import anime from 'animejs'
import { createUseStyles } from 'react-jss'

import { BP_MD, BP_LG } from '../../../constants/breakpoints'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    bottom: '53vh',
    right: 0,
    width: '75vw',
    height: '16vh',
    [`@media (min-width: ${BP_MD})`]: {
      right: '-30vw',
      width: '70vw',
    },
    [`@media (min-width: ${BP_LG})`]: {
      right: 0,
      width: '40vw',
    },
  },
})

const CloudLargeRight = () => {
  useEffect(() => {
    const anim = anime({
      targets: '#svg-cloud-right',
      skewX: ['5deg', '0deg'],
      opacity: [1, 0.75],
      easing: 'linear',
      direction: 'alternate',
      duration: 5000,
      loop: true,
      autoplay: false,
    })

    anim.play()

    return () => {
      anim.pause()
    }
  }, [])

  const classes = useStyles()

  return (
    <svg
      id="svg-cloud-right"
      className={classes.root}
      width="100%"
      height="100%"
      viewBox="0 0 124.594 37.789"
      preserveAspectRatio="none"
    >
      <linearGradient
        id="cloud-large-right"
        x1="124.594"
        x2="0"
        y1="18.895"
        y2="18.895"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3aa2b4" />
        <stop offset=".169" stopColor="#41a5b4" />
        <stop offset=".42" stopColor="#55afb2" />
        <stop offset=".721" stopColor="#76bfb0" />
        <stop offset="1" stopColor="#9bd0ae" />
      </linearGradient>
      <path
        fill="url(#cloud-large-right)"
        d="M.052 37.658s-.766-4.021 3.604-2.688c4.369 1.332 2.184-.334 2.651-1.166.469-.834 2.497-3.334 7.646-1.834 0 0 1.443.686 2.965-.471 1.521-1.154 3.687-1.842 6.437-1.029 2.749.813 4.563.688 4.797-1.25.234-1.938 1.463-5.375 5.267-3 0 0 .292-.051.526-.807.233-.756 1.346-5.193 7.664-5.756 0 0 1.756-2.188 4.506-2.063 0 0 .351-.188.351-.563s-.059-2.938 4.389-2.563c0 0 1.053-.125 1.521-.938.469-.813 3.043-3.688 7.372-2.063 0 0 .869.584 2.512-.49 1.643-1.072 2.754-.613 4.1 0 1.346.615 2.926.16 3.335-.643.41-.805 1.229-3.93 5.441-1.992 0 0 .585-5 6.554-4 0 0 .994.188 1.813-1.5s2.983-3.25 7.196-2.75 3.979 3.688 3.979 3.875c0 0 6.904-1.75 6.436 3.688 0 0 4.271-.5 3.394 3.875 0 0 5.734.188 2.282 4.313 0 0 2.926.938 2.34 4.438 0 0 7.021-2.438 6.144 1.75 0 0 3.804-2.438 5.793-.125 0 0 .451-3.313 3.531-2.063v17.943L.052 37.658z"
        opacity=".8"
      />
    </svg>
  )
}

export default CloudLargeRight
