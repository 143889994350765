import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '70vh',
  },
})

const Sky = () => {
  const classes = useStyles()

  return (
    <svg
      className={classes.root}
      width="100%"
      height="100%"
      viewBox="0 0 312.031 155.848"
      preserveAspectRatio="none"
    >
      <linearGradient
        id="sky"
        x1="156.015"
        x2="156.015"
        y2="155.848"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#369db0" />
        <stop offset=".096" stopColor="#50a5ac" />
        <stop offset=".33" stopColor="#8bb7a1" />
        <stop offset=".546" stopColor="#b9c599" />
        <stop offset=".737" stopColor="#dacf94" />
        <stop offset=".894" stopColor="#efd690" />
        <stop offset="1" stopColor="#f6d88f" />
      </linearGradient>
      <path fill="url(#sky)" d="M.022 0h312l.009 155.848H0z" />
    </svg>
  )
}

export default Sky
