import React, { useState, useEffect } from 'react'
import { Transition } from 'react-transition-group';
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

const ANIM_DURATION = 4000;
  
const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

const Fade = () => {
    const [hasMounted, setHasMounted] = useState(false);
    const useStyles = createUseStyles({
        mask: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: '#fcfdfb',
            transition: `opacity ${ANIM_DURATION}ms cubic-bezier(0.0, 0, 0.2, 1)`,
            opacity: 0,
            zIndex: 9999,
        }
    });
    const classes = useStyles();

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true);
        }, 150)
    }, [])

    return (
        <Transition in={!hasMounted} timeout={ANIM_DURATION} unmountOnExit>
            {state => (
                <div className={classNames(classes.mask)} style={{
                    ...transitionStyles[state]
                }} />
            )}
        </Transition>
    )
}

export default Fade
