import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import anime from 'animejs'

import { baseWaterAnimParams } from '../../../utils/animation'
import { BP_MD } from '../../../constants/breakpoints'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    bottom: '41vh',
    left: '35vw',
    width: '48vw',
    height: '13vh',
    [`@media (min-width: ${BP_MD})`]: {
      left: 0,
    },
  },
})

const WaterShadowLeft = () => {
  useEffect(() => {
    const anim = anime({
      targets: '#svg-water-shadow-left',
      ...baseWaterAnimParams,
      scaleX: 1.1,
      translateX: '-20%',
    })

    anim.play()

    return () => {
      anim.pause()
    }
  }, [])

  const classes = useStyles()

  return (
    <svg
      id="svg-water-shadow-left"
      className={classes.root}
      width="100%"
      height="100%"
      viewBox="0 0 129.584 29.139"
      preserveAspectRatio="none"
    >
      <linearGradient
        id="water-shadow-left"
        x2="129.584"
        y1="14.569"
        y2="14.569"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#54acb1" />
        <stop offset=".113" stopColor="#71baaf" />
        <stop offset=".251" stopColor="#8ec7ad" />
        <stop offset=".399" stopColor="#a4d2ac" />
        <stop offset=".561" stopColor="#b4d9ab" />
        <stop offset=".744" stopColor="#bddeaa" />
        <stop offset="1" stopColor="#c0dfaa" />
      </linearGradient>
      <path
        fill="url(#water-shadow-left)"
        d="M.022 0l122.181.238-33.562 1.416 40.943 1.656L56.12 4.801l60.706 2.234-36.274 1.572 20.848 1.986H8.006l87.057 1.904s-57.288 1.49-56.454 1.49c.835 0 45.696 1.986 45.696 1.986l-57.954 1.242 46.864 1.988-61.789 1.324 59.621 2.234-51.2.992 36.607 1.658-54.119.995 32.354 1.16-31.77 1.573 12.509-1.324-15.405-.58L0 25.289l22.389-.291-22.367-.869L0 22.846l33.521-.744L0 20.943v-4.348l39.983-1.035L0 14.775.022 0z"
        opacity=".7"
      />
    </svg>
  )
}

export default WaterShadowLeft
