import React from 'react'
import { createUseStyles } from 'react-jss'

import { colors } from '../../../constants/color'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '25vh',
  },
})

const Sand = () => {
  const classes = useStyles()

  return (
    <svg
      className={classes.root}
      width="100%"
      height="100%"
      viewBox="0 0 312 90"
      preserveAspectRatio="none"
    >
      <path fill="#978D6B" d="M0 0h312v90H0z" />
      <path
        fill="#C3AD80"
        d="M0 61.654s49.145-5.666 98.145 3.666c49 9.334 63.334 6.723 99.667 2.195S258.021 59.488 312 64.82V90H0V61.654z"
      />
      <path
        fill="#CBB88C"
        d="M0 65.822s4.228.5 12.103 0c7.875-.502 23.625 1.373 34.25 3.748s21.25 7.375 48.75 7.875 63.125.375 82.125 3 33.75 2.25 43.375 0 29.625-7 40.375-6.438 19.75-.438 26.75-2.438 19.42-2.625 24.272-2V90H0V65.822z"
      />
      <path
        fill={colors.sand}
        d="M0 80.82s32.811 0 42.978 2.668c10.167 2.666 14.167.666 16.167.666s15.832-1.668 22.166-.334 25.668 2.502 28.834 2.334c3.166-.166 18.332.5 21.666 1s15.668-.334 19.834-.834 14.666-.023 21.833.988 43.666 3.822 61.333 0 15-2.488 23.5-.488 10.833 4 21.333-.334c10.5-4.332 23.546-7.666 32.356-5.666V90H0v-9.18z"
      />
    </svg>
  )
}

export default Sand
