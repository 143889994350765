import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import anime from 'animejs'

import { baseWaterAnimParams } from '../../../utils/animation'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    bottom: '11vh',
    left: 0,
    width: '103%',
    height: '43vh',
  },
})

const WaterBase = () => {
  useEffect(() => {
    const anim = anime({
      targets: '#svg-water-base',
      ...baseWaterAnimParams,
    })

    anim.play()

    return () => {
      anim.pause()
    }
  }, [])

  const classes = useStyles()

  return (
    <svg
      id="svg-water-base"
      className={classes.root}
      width="100%"
      height="100%"
      viewBox="0 0 312 92.666"
      preserveAspectRatio="none"
    >
      <linearGradient
        id="water-base"
        x1="156"
        x2="156"
        y2="92.668"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fedf93" />
        <stop offset=".007" stopColor="#f4e098" />
        <stop offset=".027" stopColor="#dee2a2" />
        <stop offset=".049" stopColor="#cee4aa" />
        <stop offset=".073" stopColor="#c5e5af" />
        <stop offset=".107" stopColor="#c2e5b0" />
        <stop offset=".259" stopColor="#a1e3bc" />
        <stop offset=".411" stopColor="#86e1c5" />
        <stop offset=".5" stopColor="#7ce0c9" />
        <stop offset=".588" stopColor="#6bcfc1" />
        <stop offset=".737" stopColor="#54b8b5" />
        <stop offset=".878" stopColor="#46abae" />
        <stop offset="1" stopColor="#41a6ac" />
      </linearGradient>
      <path
        fill="url(#water-base)"
        d="M312 .291v88.125s-46.999-6.709-106.999 0c-60 6.707-60.333 5.041-108-1.959S28.999 82.123 0 84.123V0l312 .291z"
      />
    </svg>
  )
}

export default WaterBase
