// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
// ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
export function trackEvent(category, action, label, value) {
  if (!window.ga) {
    // console.warn('DEV trackEvent (not sent to GA)', {
    //   category,
    //   action,
    //   label,
    //   value,
    // })
    return false
  }

  window.ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label || null,
    eventValue: value || null,
  })
}
