import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import anime from 'animejs'

import { baseWaterAnimParams } from '../../../utils/animation'
import { BP_MD } from '../../../constants/breakpoints'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    bottom: '39vh',
    left: '45%',
    width: '20vw',
    height: '15vh',
    [`@media (min-width: ${BP_MD})`]: {
      left: '42%',
      width: '15vw',
    },
  },
})

const WaterShadowCenter = () => {
  useEffect(() => {
    const anim = anime({
      targets: '#svg-water-shadow-center',
      ...baseWaterAnimParams,
      scaleY: 1.15,
      scaleX: [0.75, 1.25],
      skewX: ['5deg', '0deg'],
      opacity: [1, 0.8],
      translateY: '7%',
    })

    anim.play()

    return () => {
      anim.pause()
    }
  }, [])

  const classes = useStyles()

  return (
    <svg
      id="svg-water-shadow-center"
      className={classes.root}
      width="100%"
      height="100%"
      viewBox="0 0 41.865 34.574"
      preserveAspectRatio="none"
    >
      <path
        fill="#FBFBEE"
        d="M0 .095C7.209.017 20.365-.065 41.865.079L20.167 2.158 37.75 3.99l-17.666.916 14.583.75s-1.833 1-7.333 1.25c0 0 2.166 1.666 1.916 3.834 0 0-6.582 1.666-8.166 2.666 0 0 9.084 3.252 9.75 3.668l-3.667.5s4.666.5 6.583 1.25c0 0-7.416 1.166-9.666 2.166 0 0 4.082.75 6.416 1.834 0 0-4.667.75-6 1.332 0 0 1.666 2.418 2 3.25 0 0-2.75 2.418-2.833 2.668l.583 1.582-2.583 2.918s.501-2.25.917-3.168c0 0-.5-1.563-.875-1.875 0 0 .563-1.02 1.125-1.291 0 0-.875-1.396-1.375-1.459 0 0 1.313-.625 1.5-.625 0 0-4.188-2.5-4.938-2.563 0 0 5.063-1.188 5.875-1.25 0 0-8.875-1.875-10.688-1.813 0 0-3.813-1.688-4.625-2.063 0 0 7.563-2.625 8.813-3.375 0 0-5.563-5.125-8.188-5.688 0 0 11-3 11.563-3.25 0 0-14.563-1.75-16.5-1.75 0 0 18.125-.188 19.75-.188 0 0-11.876-.416-12.854-.436 0 0 5.583-.354 6.875-.375L0 .095z"
        opacity=".4"
      />
    </svg>
  )
}

export default WaterShadowCenter
