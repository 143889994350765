import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

import SandImage5 from '../../assets/images/sand5.png'

import { trackEvent } from '../../utils/analytics'

import { colors } from '../../constants/color'

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    padding: '8px',

    '&::before': {
      content: "''",
      backgroundColor: colors.sand,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      zIndex: -2,
    },

    '&::after': {
      content: "''",
      backgroundImage: `url(${SandImage5})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
      opacity: 0.25,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      zIndex: -1,
    },
  },
  contentWrap: {
    maxWidth: '75vw',
    margin: '160px auto',
  },
  header: {
    textAlign: 'center',
  },
  linkedInLink: {
    display: 'inline-block',
    margin: '16px 0 0 0',
    opacity: 0.9,
    width: '64px',
    transform: 'rotate(-1deg)',
    transition: 'all 0.3s ease',
    '&:hover': {
      opacity: 1,
    },
  },
  footer: {
    fontSize: '12px',
    textAlign: 'center',
    userSelect: 'none',
  },
})

const BeachBody = () => {
  const classes = useStyles()

  const handleLinkedInClick = () => {
    trackEvent('LinkedIn', 'Click', 'LinkedIn profile view')
  }

  return (
    <>
      <section className={classes.root}>
        <header className={classnames(classes.contentWrap, classes.header)}>
          <h1>Web Developer</h1>
          <p>Based on the Hibiscus Coast, Auckland, New Zealand.</p>
          <a
            onClick={handleLinkedInClick}
            className={classes.linkedInLink}
            href="https://www.linkedin.com/in/mike-francis-43666553/"
            title="View my LinkedIn profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 382 382"
            >
              <path
                fill="#C3AD80"
                d="M347.445 0H34.555C15.471 0 0 15.471 0 34.555v312.889C0 366.529 15.471 382 34.555 382h312.889C366.529 382 382 366.529 382 347.444V34.555C382 15.471 366.529 0 347.445 0zM118.207 329.844c0 5.554-4.502 10.056-10.056 10.056H65.345c-5.554 0-10.056-4.502-10.056-10.056V150.403c0-5.554 4.502-10.056 10.056-10.056h42.806c5.554 0 10.056 4.502 10.056 10.056v179.441zM86.748 123.432c-22.459 0-40.666-18.207-40.666-40.666S64.289 42.1 86.748 42.1s40.666 18.207 40.666 40.666-18.206 40.666-40.666 40.666zM341.91 330.654a9.247 9.247 0 0 1-9.246 9.246H286.73a9.247 9.247 0 0 1-9.246-9.246v-84.168c0-12.556 3.683-55.021-32.813-55.021-28.309 0-34.051 29.066-35.204 42.11v97.079a9.246 9.246 0 0 1-9.246 9.246h-44.426a9.247 9.247 0 0 1-9.246-9.246V149.593a9.247 9.247 0 0 1 9.246-9.246h44.426a9.247 9.247 0 0 1 9.246 9.246v15.655c10.497-15.753 26.097-27.912 59.312-27.912 73.552 0 73.131 68.716 73.131 106.472v86.846z"
              />
            </svg>
          </a>
        </header>
        <footer className={classes.footer}>&copy;2020 Mike Francis</footer>
      </section>
    </>
  )
}

export default BeachBody
