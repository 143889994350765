import React from 'react'

import Layout from '../components/layout'
import Fade from '../components/fade/Fade'
import Beach from '../components/beach/Beach'
import BeachBody from '../components/beach-body/BeachBody'

const IndexPage = () => (
  <>
    <Fade />
    <Layout>
      <Beach />
      <BeachBody />
    </Layout>
  </>
)

export default IndexPage
