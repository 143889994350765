import { DEFAULT_ANIM_DURATION } from '../constants/animation'

export const baseWaterAnimParams = {
  scaleY: 1.05,
  scaleX: 1.2,
  translateY: '2.6%',
  easing: 'cubicBezier(.42,-0.11,.53,1.16)',
  direction: 'alternate',
  duration: DEFAULT_ANIM_DURATION,
  loop: true,
  autoplay: false,
}
