import React, { useEffect } from 'react'
import anime from 'animejs'
import { createUseStyles } from 'react-jss'

import { BP_MD, BP_LG } from '../../../constants/breakpoints'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    bottom: '53vh',
    left: 0,
    width: '80vw',
    height: '16vh',
    [`@media (min-width: ${BP_MD})`]: {
      left: '-30vw',
      width: '70vw',
    },
    [`@media (min-width: ${BP_LG})`]: {
      left: 0,
      width: '40vw',
    },
  },
})

const CloudLargeLeft = () => {
  useEffect(() => {
    const anim = anime({
      targets: '#svg-cloud-left',
      skewX: ['-5deg', '0deg'],
      opacity: [1, 0.75],
      easing: 'linear',
      direction: 'alternate',
      duration: 5000,
      loop: true,
      autoplay: false,
    })

    anim.play()

    return () => {
      anim.pause()
    }
  }, [])

  const classes = useStyles()

  return (
    <svg
      id="svg-cloud-left"
      className={classes.root}
      width="100%"
      height="100%"
      viewBox="0 0 123.431 36.224"
      preserveAspectRatio="none"
    >
      <linearGradient
        id="cloud-large-left"
        x2="123.431"
        y1="18.112"
        y2="18.112"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3aa2b4" />
        <stop offset=".169" stopColor="#41a5b4" />
        <stop offset=".42" stopColor="#55afb2" />
        <stop offset=".721" stopColor="#76bfb0" />
        <stop offset="1" stopColor="#9bd0ae" />
      </linearGradient>
      <path
        fill="url(#cloud-large-left)"
        d="M81.75 19.595c3.771-1.293 3.529 1.457 3.529 1.457 7.343-.291 3.09 3.293 3.09 3.293 3.49-.209 2.688 2.457 2.688 2.457 6.339-.875 7.302 2.875 7.744 3.584.441.709 1.403.709 1.403.709 8.667-1.25 7.944 1.125 7.904 1.5-.041.375.401.416.401.416 6.459-1.418 7.583 1.582 7.583 1.582 6.5-1.125 7.337 1.631 7.337 1.631L0 36.095V13.761s1.062-.875 3.469 0 .723.098 1.565-.389c.842-.486 3.852-3.111 9.509 1.264 0 0 .12-5.375 8.185-3.875 0 0-.602-3.75 2.528-6.75 3.129-3 5.537-5 7.703-3.5 2.167 1.5 2.648.375 3.973 0 1.323-.375 7.703-1.75 7.102 5.5 0 0 4.093-1 2.889 4.5 0 0 3.37-1.875 4.694-2s3.731-1.75 4.213-2.125 2.527-.75 3.37 1.375 2.648.375 3.13 0 4.333-1.625 3.972 1.625c0 0 5.297-2.375 4.936 2.125 0 0 .421.813.843.875.421.063 3.972.188 3.37 3.688 0 0 .541.5 1.324.313.781-.189 5.997-1.48 4.975 3.208"
        opacity=".8"
      />
    </svg>
  )
}

export default CloudLargeLeft
