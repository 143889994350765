import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import anime from 'animejs'

import { baseWaterAnimParams } from '../../../utils/animation'
import { BP_MD } from '../../../constants/breakpoints'

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    bottom: '41vh',
    right: '35vw',
    width: '48vw',
    height: '13vh',
    [`@media (min-width: ${BP_MD})`]: {
      right: 0,
    },
  },
})

const WaterShadowRight = () => {
  useEffect(() => {
    const anim = anime({
      targets: '#svg-water-shadow-right',
      ...baseWaterAnimParams,
      scaleX: 1.1,
      translateX: '20%',
    })

    anim.play()

    return () => {
      anim.pause()
    }
  }, [])

  const classes = useStyles()

  return (
    <svg
      id="svg-water-shadow-right"
      className={classes.root}
      width="100%"
      height="100%"
      viewBox="0 0 132.969 27.498"
      preserveAspectRatio="none"
    >
      <linearGradient
        id="water-shadow-right"
        x1="132.969"
        x2="0"
        y1="13.749"
        y2="13.749"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#54acb1" />
        <stop offset=".113" stopColor="#71baaf" />
        <stop offset=".251" stopColor="#8ec7ad" />
        <stop offset=".399" stopColor="#a4d2ac" />
        <stop offset=".561" stopColor="#b4d9ab" />
        <stop offset=".744" stopColor="#bddeaa" />
        <stop offset="1" stopColor="#c0dfaa" />
      </linearGradient>
      <path
        fill="url(#water-shadow-right)"
        d="M132.96 0H0l57.604 2-34.833 1.166 69 1.498-87 1.168 37.667 2.166-11.5 1 58.5 1.334-47 1.666 40.833 1-63.833 1.666 100.666 2.5H60.271l35.5 1.668-35 1.332 60.833 1.334-64 2.166 71.667 1-44.167 1.668 47.856 1.166-17.689-1.334 17.689-1.166-.022-1.209-12.355-.603 12.377-.5-.022-2.5-27-.616 27.022-.312.009-6.875L116 11.945l16.969-.781-.031-2.125-24.688-.375 24.719-1z"
        opacity=".7"
      />
    </svg>
  )
}

export default WaterShadowRight
