import React, { useEffect } from 'react'
import anime from 'animejs'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  sun: {
    margin: '0 auto',
    marginTop: '37vh',
    width: '7vh',
    height: '7vh',
    borderRadius: '50%',
    background: '#ffffff',
    opacity: 0.9,
    boxShadow: `
      0px 0px 30px 15px #ffffff,
      0px 0px 50px 20px #F0EA83,
      0px 0px 100px 30px #F5DC93
    `,
  },
})

const Sun = () => {
  useEffect(() => {
    const anim = anime({
      targets: '#sun',
      scaleX: [0.97, 1.03],
      scaleY: [1.03, 0.95],
      opacity: [0.75, 1],
      easing: 'linear',
      direction: 'alternate',
      duration: 1500,
      loop: true,
      autoplay: false,
    })

    anim.play()

    return () => {
      anim.pause()
    }
  }, [])

  const classes = useStyles()

  return <div id="sun" className={classes.sun} />
}

export default Sun
